<template>
  <h1><img alt="Vue logo" src="./../../assets/cat.jpg" width="300" /></h1>
  <Intro />
</template>

<script>
  import Intro from './../../components/Intro.vue'

  export default {
    name: 'App',
    components: {
      Intro,
    },
  }
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }
</style>
