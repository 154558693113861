<template>
  <div class="hello">
    <p>I am a cat.</p>
  </div>
</template>

<script>
  export default {
    name: 'HelloWorld',
    props: {
      msg: String,
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .hello {
    max-width: 600px;
    margin: 0 auto;
  }
  p {
    line-height: 1.6;
  }
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    margin: 20px 0;
  }
  a {
    color: #c85817;
  }
</style>
